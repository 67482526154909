nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
nav button {
  background: transparent;
  cursor: pointer;
  border: 2px solid #414141;
  padding: 0.5rem;
  transition: all 0.3s ease;
}
nav button:hover {
  background: #414141;
  color: white;
}
@media screen and (max-width: 768px) {
  nav button {
    z-index: 10;
  }
}
