.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.song-container img {
  width: 20%;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.song-container h2 {
  padding: 3rem 1rem 1rem 1rem;
}
.song-container h3 {
  font-size: 1rem;
}
.song-playing {
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
 }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
 }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
 }
}
@media screen and (max-width: 768px) {
  .song-container img {
    width: 50%;
 }
}
